
// Body text
body {
	color: $color-text;
	font-family: $font-family-body;
	font-size: $font-size-body;
	line-height: 1.7;
	font-weight: $font-normal;
}

