
// Content styles
.block-text {
	line-height: 1.7;
	
	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		// &.intro {
		// 	padding: 0 0 $paragraph-margin;
		// 	border-bottom: $border;
		// 	color: $color-sub;
		// 	font-family: $font-family-sub;
		// 	font-size: $h4;
			
		// 	@media #{$mobileXL} {
		// 		font-size: $h3;
		// 	}
		// }
	}
	
	// New default <ul> lists
	ul {
		@extend %default-ul;
		margin-left: $block-padding;
	}
	
	// New default <ol> lists
	ol {
		@extend %default-ol;
		margin-left: $block-padding;
	}

}

